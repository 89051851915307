import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import '../styles/styles_index.css'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IndexPage = () => {
  const navigate = useNavigate();

  const handleImageClick = (minister) => {
    navigate(`/stats?minister=${minister}`);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const textElements = document.querySelectorAll('.custom-slide-in');
    const button = document.querySelector('.custom-btn');

    setTimeout(() => {
      textElements.forEach((text, index) => {
        setTimeout(() => {
          text.classList.add('show');
        }, index * 50);
      });
    }, 300);

    setTimeout(() => {
      button.classList.add('show');
    }, 600);

    return () => {};
  }, []);

  const calculateSlidesToShow = () => {
    const imageWidth = 250;
    const viewportWidth = window.innerWidth;
    return Math.floor(viewportWidth / imageWidth);
  };

  const sliderSettings = {
    infinite: true,
    speed: 1500,
    slidesToShow: calculateSlidesToShow(),
    autoplay: true,
    autoplayspeed: 30,
    arrows: true,
    dots: false,
    centerMode: false,
    centerPadding: '0',
    pauseOnHover: true,
    swipeToSlide: true,
    draggable: true, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: calculateSlidesToShow(),
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        }
      }
    ]
  };

  const ministers = [
    { name: 'Salvador Illa', imgSrc: '/assets/salvador_illa.png' },
    { name: 'Albert Dalmau', imgSrc: '/assets/albert_dalmau.png' },
    { name: 'Alícia Romero', imgSrc: '/assets/alícia_romero.png' },
    { name: 'Núria Parlon', imgSrc: '/assets/núria_parlon.png' },
    { name: 'Ramon Espadaler', imgSrc: '/assets/ramon_espadaler.png' },
    { name: 'Sílvia Paneque', imgSrc: '/assets/sílvia_paneque.png' },
    { name: 'Olga Pané', imgSrc: '/assets/olga_pané.png' },
    { name: 'Esther Niubó', imgSrc: '/assets/esther_niubó.png' },
    { name: 'Mònica Martínez', imgSrc: '/assets/mònica_martínez.png' },
    { name: 'Miquel Sàmper', imgSrc: '/assets/miquel_sàmper.png' },
    { name: 'Eva Menor', imgSrc: '/assets/eva_menor.png' },
    { name: 'Jaume Duch', imgSrc: '/assets/jaume_duch.png' },
    { name: 'Núria Montserrat', imgSrc: '/assets/núria_montserrat.png' },
    { name: 'Òscar Ordeig', imgSrc: '/assets/òscar_ordeig.png' },
    { name: 'Bernardo Álvarez', imgSrc: '/assets/bernardo_álvarez.png' },
    { name: 'Sònia Hernández', imgSrc: '/assets/sònia_hernández.png' },
    { name: 'Francesc Xavier Vila', imgSrc: '/assets/francesc_xavier_vila.png' },
  ];

  return (
    <div>
      <header>
        <nav className="custom-navbar">
          <div className="custom-logo-container">
            <div className="custom-logo">
              <img src="/assets/logoECO_blanc.png" alt="Logo" />
            </div>
            <div className="custom-menu-icon" id="menuIcon" onClick={toggleMenu}>
              <span className="material-symbols-outlined">menu</span>
            </div>
          </div>
          <div className={`custom-nav-links ${menuOpen ? 'show' : ''}`} id="navLinks">
            <a href="/stats">Explora les dades</a>
            <a href="https://www.femeco.cat/index.php/ca/">Qui som</a>
            <a href="https://femeco.cat/index.php/ca/ecos-corner/estrateco">EstratECO</a>
            <a href="/faqs">FAQ's</a>
            {menuOpen && (
              <span className="custom-close-icon material-symbols-outlined" onClick={toggleMenu}>
                close
              </span>
            )}
          </div>
        </nav>
      </header>

      <div className="custom-container">
        <h1 className="custom-slide-in">On va el Govern?</h1>
        
        <Slider {...sliderSettings}>
          {ministers.map((minister, index) => (
            <div key={index} className="custom-img-container">
              <img 
                src={minister.imgSrc} 
                alt={`Imatge de ${minister.name}`} 
                className="custom-img" 
                onClick={() => handleImageClick(minister.name)}
              />
              <div className="overlay">{minister.name}</div>
            </div>
          ))}
        </Slider>

        <div className="custom-text-overlay">
          <a href="/stats" className="custom-btn">Descobreix-ho</a>
        </div>
      </div>

      <footer className="custom-footer">
        <h6 className='custom-h6'>Copyright © 2024 Estratègia, Comunicació i Oratòria S.L. Tots els drets reservats.</h6>
      </footer>
    </div>
  );
};

export default IndexPage;
