import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import IndexPage from './pages/IndexPage';
import StatsPage from './pages/StatsPage';
import FaqsPage from './pages/FaqsPage'
import './styles/index.css';
import './styles/styles_index.css'; 
import './styles/styles_stats.css';
import ReactGA from 'react-ga4';
import usePageTracking from './usePageTracking'; 

function App() {
  useEffect(() => {
    ReactGA.initialize('G-JXFT01DHQD'); 
  }, []);

  return (
    <Router>
      <TrackedRoutes />
    </Router>
  );
}

function TrackedRoutes() {
  usePageTracking();

  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/stats" element={<StatsPage />} />
      <Route path="/faqs" element={<FaqsPage />} />
    </Routes>
  );
}

export default App;
